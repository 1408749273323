import DOMPurify from "dompurify";

export const required = () => (value) => !value ? "Required" : false;
export const objectOrArrayRequired = () => (objectOrArrayValue) =>
  Object.keys(objectOrArrayValue).length === 0 ? "Required" : false;
export const minLength = (min) => (value) =>
  value?.length < min ? `Minimum ${min} length` : false;
export const maxLength = (max) => (value) =>
  value?.length > max ? `Maximum ${max} length` : false;
export const matches = (matchingValue) => (value) =>
  matchingValue !== value ? "Must match" : false;
export const domain = () => (value) => {
  const areAnyInvalid = value.some((v) => {
    return !/^[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v);
  });

  return areAnyInvalid ? "Must be a valid domain name" : false;
};
export const noDuplicateArrayItems = () => (arrayValue) => {
  const areAnyDuplicates = arrayValue.some((v) => {
    return arrayValue.filter((av) => av === v).length > 1;
  });

  return areAnyDuplicates ? "Must not contain duplicates" : false;
};

export const email = () => (value) =>
  value && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
    ? "Must be a valid email address"
    : false;
export const password = () => minLength(8);
export const phone = () => (value) =>
  !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value)
    ? "Must be a valid phone number"
    : false;
export const lowercase = () => (value) =>
  value !== value.toLowerCase() ? "Must be lower case" : false;
export const notEquals =
  (...reservedWords) =>
  (value) => {
    for (const reservedWord of reservedWords) {
      if (reservedWord === value) {
        return `${reservedWord} is a reserved word`;
      }
    }
    return false;
  };

export const lettersAndNumbers = () => (value) =>
  !/^[a-zA-Z0-9]*$/.test(value)
    ? "Must only contain letters and numbers"
    : false;
export const startsWithLetter = () => (value) =>
  !/^[a-zA-Z]+/.test(value.substr(0, 1)) ? "Must start with a letter" : false;

export const doesNotMatchCurrentPasswordError = "Must be a new password";
export const doesNotMatchCurrentPassword = () => (newPassword, field) => {
  return field.form.values.oldPassword === newPassword
    ? doesNotMatchCurrentPasswordError
    : false;
};

export const newAndConfirmPasswordsMatchError = "Passwords must match";
export const newAndConfirmPasswordsMatch = () => (confirmPassword, field) => {
  return field.form.values.newPassword !== confirmPassword
    ? newAndConfirmPasswordsMatchError
    : false;
};

export const validate =
  (...validators) =>
  (value, field) => {
    let error = XSSValidation(value, field);
    console.log(field, value, error);

    if (error) return error;

    for (const validator of validators) {
      error = validator(value, field);

      console.log("loop", field, value, error);
      if (error) {
        return error;
      }
    }

    return false;
  };

export const XSSValidationError =
  "Must be a valid text, html tags are not allowed";
export const XSSValidation = (str) => {
  if (!str) return false;
  const trimmedStr = String(str).trim();
  const clean = DOMPurify.sanitize(trimmedStr, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: [],
  });
  return clean !== trimmedStr ? XSSValidationError : false;
};
